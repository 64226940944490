<template>
  <div>
    <div :class="'company_nav'+scroll_class">
      <ul class="nav nav-admin">
        <li>
          <router-link to="/admin/dashboard">
            <i class="material-icons">dashboard</i>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/reservations">
            <i class="material-icons">today</i>
            <span>Reservierungen</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/availability">
            <i class="material-icons">event_available</i>
            <span>Verfügbarkeit</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/calendar">
            <i class="material-icons">date_range</i>
            <span>Buchungsübersicht</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/vehicles">
            <i class="material-icons">drive_eta</i>
            <span>Fahrzeuge</span>
          </router-link>
          <ul>
            <li>
              <router-link to="/admin/vehicle_categories">Kategorien</router-link>
            </li>
            <li>
              <router-link to="/admin/vehicle_makes">Marken</router-link>
            </li>
            <li>
              <router-link to="/admin/vehicle_groups">Gruppen</router-link>
            </li>
            <li>
              <router-link to="/admin/vehicle_extras">Extras</router-link>
            </li>
          </ul>
        </li>
        <li v-if="user.admin">
          <router-link to="/admin/locations">
            <i class="material-icons">gps_fixed</i>
            <span>Standorte</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/customers">
            <i class="material-icons">group</i>
            <span>Kunden</span>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/documents">
            <i class="material-icons">description</i>
            <span>Dokumente</span>
          </router-link>
        </li>
        <!--<li v-if="user.admin">
          <router-link to="/admin/settings">
            <i class="material-icons">settings</i>
            <span>Einstellungen</span>
          </router-link>
        </li>-->
        <li v-if="user.admin">
          <router-link to="/admin/statistics">
            <i class="material-icons">insert_chart</i>
            <span>Statistiken</span>
          </router-link>
        </li>
      </ul>

      <div class="version">
        <p>Version {{ meta.version }}<small>Commit {{ meta.commit }}</small></p>
      </div>

    </div>
    <span style="color:#fff;">_</span>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'admin_nav',
  data () {
    return {
      meta: {},
      user: {},
      scroll_class: ""
    }
  },
  methods: {
    get_version() {
      axios.get(process.env.VUE_APP_BASE_API, {})
      .then(response => {
        this.meta = response.data;
      })
    },
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.user = response.data.user;
      })
    },
    on_scroll() {
      if (window.top.scrollY > 129 && window.innerWidth > 1000) {
        this.scroll_class = " company_nav_top";
      } else {
        this.scroll_class = "";
      }
    }
  },
  mounted () {
    this.get_version();
    this.get_data();
    window.addEventListener("scroll", this.on_scroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.on_scroll)
  },
}

</script>

<style lang="scss">

  .version {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    color: #999;

    small {
      display: block;
      margin-top: 2px;
    }
  }

  .company_nav_top {
    position: fixed;
    top: 30px;
  }

  @media (max-width: 770px) {

    .version {
      display: none;
    }

  }

</style>
