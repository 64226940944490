<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_categories">Kategorien</router-link></li>
              <li>{{ vehicle_category.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_categories" class="button button-grey button-small">Alle Kategorien ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li class="active"><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/prices'">Preise</router-link></li>
            <li><router-link :to="'/admin/vehicle_categories/'+this.$route.params.id+'/extras'">Extras</router-link></li>
          </ul>

          <div class="admin-body">

            <form @submit="update_vehicle_category" class="form" method="post">

              <div class="form-wrap">
                <label for="name">Name</label>
                <input v-model="vehicle_category.name" class="form-input" type="text" name="name" id="name" placeholder="Name">
              </div>

              <p v-if="vehicle_category.image"><img :src="vehicle_category.image" width="200"></p>

              <div class="form-wrap">
                <label>Bild</label>
                <input type="file" @change="set_image">
              </div>

              <div class="form-wrap select-wrap">
                <label for="status">Status</label>
                <select v-model="vehicle_category.status" class="select-wrap">
                  <option value="enabled">Aktiviert</option>
                  <option value="disabled">Deaktiviert</option>
                </select>
              </div>

              <div class="form-wrap">
                <label for="tagline">Tagline</label>
                <input v-model="vehicle_category.tagline" class="form-input" type="text" name="tagline" id="tagline" placeholder="Tagline">
              </div>

              <div class="row row-gutter-20">
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="seats">Sitze</label>
                    <input v-model="vehicle_category.seats" class="form-input" type="text" name="seats" id="seats" placeholder="2">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="doors">Türen</label>
                    <input v-model="vehicle_category.doors" class="form-input" type="text" name="doors" id="doors" placeholder="5">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="luggage">Gepäck</label>
                    <input v-model="vehicle_category.luggage" class="form-input" type="text" name="luggage" id="luggage" placeholder="20">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap select-wrap">
                    <label for="drive">Getriebe</label>
                    <select v-model="vehicle_category.drive" class="select-wrap">
                      <option value="manual">Schalter</option>
                      <option value="automatic">Automatik</option>
                      <option value="not_any">Keine</option>
                    </select>
                  </div>
                </div>
              </div>

              {{ errors }}

              <p>
                <input type="submit" value="Kategorie speichern ›" class="button button-orange button-100">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      locations: [],
      vehicle_category: {},
      temp_image: ""
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_category = response.data.vehicle_category;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_vehicle_category: function (e) {
      e.preventDefault();

      var form_data = new FormData();
      form_data.append("name", this.vehicle_category.name);
      form_data.append("status", this.vehicle_category.status);
      form_data.append("tagline", this.vehicle_category.tagline);
      form_data.append("seats", this.vehicle_category.seats);
      form_data.append("doors", this.vehicle_category.doors);
      form_data.append("luggage", this.vehicle_category.luggage);
      form_data.append("drive", this.vehicle_category.drive);
      form_data.append("price_per_day", this.vehicle_category.price_per_day);
      form_data.append("location_id", this.vehicle_category.location_id);
      form_data.append("image", this.temp_image);

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.$route.params.id, form_data, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$router.push('/admin/vehicle_categories');
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    },
    set_image(event) {
      this.temp_image = event.target.files[0];
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
